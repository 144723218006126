import { Unstable_Grid2 as Grid } from '@mui/material';
import { useCallback } from 'react';
import { withoutIndex } from '../functions';
import EditableWord from './EditableWord';

type Props = {
    words: string[],
    onChange: (words: string[]) => void
};

const EditableWordList = ({ words, onChange }: Props) => {
    const onWordDeleted = useCallback((index: number) => () => {
        if (index >= 0) {
            onChange(withoutIndex(words, index));
        }
    }, [onChange, words]);

    const onWordChanged = useCallback((index: number) => (newWord: string) => {
        if (index >= 0) {
            onChange(words.map((w, idx) => idx === index ? newWord : w));
        } else {
            onChange(words.concat(newWord));
        }
    }, [onChange, words]);
    return (
        <Grid container sx={{ width: '100%' }} columns={Math.min(words.length + 1, 3) * 4}>
            {words.map((word, index) => <Grid xs={4} key={word} display="flex" justifyContent="center" alignItems="center"><EditableWord word={word} onChange={onWordChanged(index)} onDelete={onWordDeleted(index)}/></Grid>)}
            <Grid xs={4} display="flex" justifyContent="center" alignItems="center"><EditableWord word="" onChange={onWordChanged(-1)} onDelete={onWordDeleted(-1)}/></Grid>
        </Grid>
    );
};

export default EditableWordList;