import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { getFilledMatrix } from '../functions';
import { getAnswerKey, WordSearch } from '../models';
import LetterTable from './LetterTable';
import WordList from './WordList';

type Props = {
    wordSearch: WordSearch,
    showAnswer?: boolean,
    title: string
};

const WordSearchContainer = ({ wordSearch, showAnswer, title }: Props) => {
    const answers = useMemo(() => {
        if (showAnswer) {
            return getAnswerKey(wordSearch);
        } else {
            return getFilledMatrix(wordSearch.height, wordSearch.width, false);
        }
    }, [wordSearch, showAnswer]);

    return (
        <Stack direction="column" sx={{ alignItems: 'center' }}>
            <Typography variant="h1">{title}</Typography>
            <LetterTable grid={wordSearch.grid} answers={answers} showAnswer={showAnswer}/>
            <WordList words={wordSearch.words} />
        </Stack>
    );
};

export default WordSearchContainer;