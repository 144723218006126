export const getQueryParam = (key: string, query = location.queryParams): string | null => {
    return query.get(key);
};

export const getAllQueryParam = (key: string, query = location.queryParams): string[] => {
    return query.getAll(key);
};

export const getQueryObject = <T extends string>(...keys: T[]): { [K in typeof keys[number]]: string | null } => Object.fromEntries(keys.map(key => [key, getQueryParam(key)])) as { [K in typeof keys[number]]: string | null };

const possibleRoutes = new Set(['new', 'generate']);
const determineUrlParts = () => {
    const pathParts = window.location.pathname.split('/').filter(x => x);

    const currentRoute = (pathParts.length && pathParts[pathParts.length - 1]) || '';

    return {
        current: currentRoute,
        valid: possibleRoutes.has(currentRoute),
        queryParams: new URLSearchParams(window.location.search)
    };
};

const convertRecordToSearch = (query: Record<string, string | string[]>) => {
    const params = new URLSearchParams();
    Object.entries(query).forEach(([key, values]) => {
        if (Array.isArray(values)) {
            values.forEach(v => params.append(key, v));
        } else {
            params.set(key, values);
        }
    });

    return params.toString();
};

export const location = determineUrlParts();
export const getNextLocation = (path: string, query?: Record<string, string | string[]>) => {
    const fullUrl = new URL(window.location.href);
    fullUrl.pathname = path;

    if (query) {
        fullUrl.search = convertRecordToSearch(query);
    }

    return fullUrl.href;
};

export const navigate = (path: string, query?: Record<string, string | string[]>) => {
    window.location.assign(getNextLocation(path, query));
};
export const replace = (path: string, query?: Record<string, string | string[]>) => {
    window.history.replaceState({}, '', getNextLocation(path, query));
};
