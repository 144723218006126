import { Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import GenerateWordSearchPage from "./pages/GenerateWordSearchPage";
import NewWordSearchPage from "./pages/NewWordSearchPage";
import { location } from './services/load-parameters';

const pages: Record<string, ReactElement> = {
  generate: <GenerateWordSearchPage />,
  new: <NewWordSearchPage />
};

const App = () => {
  const currentPage = location.valid && location.current && pages[location.current];
  if (currentPage) {
    return currentPage;
  }

  return (
    <Stack>
      <Typography>No page was found at this location. Please verify the path in the URL.</Typography>
    </Stack>
  )
};

export default App;
