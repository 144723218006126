import { Box } from '@mui/material';

type Props = React.ComponentProps<typeof Box>;

const HideFromPrint = ({ children, sx, ...rest }: Props) => (
    <Box sx={{ ...sx, displayPrint: 'none' }} {...rest}>
        {children}
    </Box>
);

export default HideFromPrint;