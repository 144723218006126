export interface Maybe<T> {
    then(callback: (val: T) => void): Maybe<T>;
    map<V>(map: (val: T) => V | null): Maybe<V>;
    flatmap<V>(map: (val: T) => Maybe<V>): Maybe<V>;
    else<V>(callback: () => Maybe<V>): Maybe<V>;
    catch(callback: (err: string) => T): Maybe<T>;
    getOrDefault<V>(def: V): T | V
}

class Empty<T> implements Maybe<T> {
    getOrDefault<V>(def: V) {
        return def;
    }

    then() {
        return new Empty<T>();
    }

    map<V>() {
        return new Empty<V>();
    }

    flatmap<V>() {
        return new Empty<V>();
    }

    else<V>(cb: () => Maybe<V>) {
        return cb();
    }

    catch() {
        return new Empty<T>();
    }
}

class Value<T> implements Maybe<T> {
    constructor(private val: T)
    {}

    getOrDefault() {
        return this.val;
    }

    then(cb: (val: T) => void) {
        cb(this.val);
        return new Value<T>(this.val);
    }

    map<V>(cb: (val: T) => V | null) {
        return wrap(cb(this.val));
    }

    flatmap<V>(cb: (val: T) => Maybe<V>) {
        return cb(this.val);
    }

    else<V>() {
        return new Empty<V>();
    }

    catch() {
        return new Empty<T>();
    }
}

class Exception<T> implements Maybe<T> {
    constructor(private error: string) {}

    getOrDefault<V>(def: V) {
        return def;
    }

    then() {
        return new Exception<T>(this.error);
    }

    map<V>() {
        return new Exception<V>(this.error);
    }

    flatmap<V>() {
        return new Exception<V>(this.error);
    }

    else<V>() {
        return new Exception<V>(this.error);
    }

    catch(cb: (err: string) => T) {
        return wrap(cb(this.error));
    }
}

export const wrap = <T>(value: T | null): Maybe<T> => value === null ? new Empty<T>() : new Value<T>(value);
export const trycatch = <T>(supplier: () => T | null): Maybe<T> => {
    try {
        return wrap(supplier());
    } catch(e: unknown) {
        return new Exception<T>(`${e}`);
    }
};
