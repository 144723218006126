import { IconButton, InputAdornment } from '@mui/material';

export type Action = {
    icon: React.ReactNode,
    onClick: () => void,
    key: string
};

type Props = {
    actions: Action[]
};

const InlineFormActions = ({ actions }: Props) => {
    return (
        <>
        {
            actions.map(action => <InputAdornment key={action.key} position="end"><IconButton onClick={action.onClick}>{action.icon}</IconButton></InputAdornment>)
        }
        </>
    );
};

export default InlineFormActions;