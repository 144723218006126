import { Box } from '@mui/material';
import { useMemo } from 'react';
import { generateKey } from '../functions';
import Letter from './Letter';

type Props = {
    grid: string[][],
    answers: boolean[][],
    showAnswer?: boolean
};

const LetterTable = ({ grid, answers, showAnswer }: Props) => {
    const rows = useMemo(() => grid.length, [grid]);
    const columns = useMemo(() => grid[0].length, [grid]);
    const letterWidth = useMemo(() => 100 / columns, [columns]);
    const letterHeight = useMemo(() => 60 / rows, [rows]);

    return (
        <Box sx={{ display: 'grid', gridTemplateRows: `repeat(${rows}, 1fr)`, gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
            {
                grid.flatMap((letters, row) => letters.map((letter, col) => <Letter key={generateKey(row, col)} showAnswer={!!showAnswer} sx={{ fontSize: `min(${letterWidth}vw, ${letterHeight}vh)` }} letter={letter} answer={answers[row][col]}/>))
            }
        </Box>
    );
};

export default LetterTable;