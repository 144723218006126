import { Checkbox, FormControlLabel, IconButton, Stack, Tooltip } from '@mui/material';
import StartOverIcon from '@mui/icons-material/Cancel';
import GoBackIcon from '@mui/icons-material/Edit';
import ReloadIcon from '@mui/icons-material/Cached';
import PrintIcon from '@mui/icons-material/Print';
import { navigate } from '../services/load-parameters';

const startOver = () => navigate('new', {});
const regenerate = () => window.location.reload();
const goBack = () => navigate('new');
const print = () => window.print();

const buttonStyle = {
    size: 'large' as const
};

type Props = {
    onAnswerChange: (val: boolean) => void,
    showAnswer: boolean
};

const WordSearchActions = ({ showAnswer, onAnswerChange }: Props) => (
    <Stack direction="column" sx={{ justifyContent: 'center' }}>
        <Stack direction="row">
            <Tooltip title="Start over">
                <IconButton onClick={startOver} {...buttonStyle}>
                    <StartOverIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit the current options">
                <IconButton onClick={goBack} {...buttonStyle}>
                    <GoBackIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Regenerate with current options">
                <IconButton onClick={regenerate} {...buttonStyle}>
                    <ReloadIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Print current word search">
                <IconButton onClick={print} {...buttonStyle}>
                    <PrintIcon />
                </IconButton>
            </Tooltip>
        </Stack>
        <FormControlLabel control={<Checkbox checked={showAnswer} onChange={e => onAnswerChange(e.target.checked)}/>} label="Show Answer?" />
    </Stack>
);

export default WordSearchActions;