import { getFilledMatrix } from '../functions';

export type Point = {
    row: number,
    col: number
};

export type Delta = 0 | 1 | -1;

export type Direction = {
    vertical: Delta,
    horizontal: Delta
};

const makeDirection = (vertical: Delta, horizontal: Delta): Direction => ({ vertical, horizontal });
export const UP = makeDirection(-1, 0);
export const DOWN = makeDirection(1, 0);
export const LEFT = makeDirection(0, -1);
export const RIGHT = makeDirection(0, 1);
export const UP_RIGHT = makeDirection(-1, 1);
export const UP_LEFT = makeDirection(-1, -1);
export const DOWN_RIGHT = makeDirection(1, 1);
export const DOWN_LEFT = makeDirection(1, -1);
export const ALL_DIRECTIONS = [
    UP,
    DOWN,
    LEFT,
    RIGHT,
    UP_RIGHT,
    UP_LEFT,
    DOWN_RIGHT,
    DOWN_LEFT
];

export type PlacedWord = {
    word: string,
    startingPoint: Point,
    direction: Direction
};

export type WordSearch = {
    width: number,
    height: number,
    words: string[],
    grid: string[][],
    key: PlacedWord[]
};

export const allLetters = (word: PlacedWord) => [...word.word].map((letter, index) => {
    const newPoint: Point = {
        row: word.startingPoint.row + (index * word.direction.vertical),
        col: word.startingPoint.col + (index * word.direction.horizontal)
    };

    return [letter, newPoint] as [string, Point];
});

export const getAnswerKey = (wordSearch: WordSearch): boolean[][] => {
    const answers = getFilledMatrix(wordSearch.height, wordSearch.width, false);

    wordSearch.key.flatMap(allLetters).map(([, point]) => point).forEach(p => answers[p.row][p.col] = true);

    return answers;
};
