import { Box, Grid, Typography } from '@mui/material';

type Props = {
    words: string[]
};

const WordList = ({ words }: Props) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container>
                {words.map(word => <Grid item xs={4} key={word} display="flex" justifyContent="center" alignItems="center"><Typography variant="h3">{word}</Typography></Grid>)}
            </Grid>
        </Box>
    );
};

export default WordList;