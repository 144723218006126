import { getFilledMatrix, joinArrays, randomValue, sanitize, shuffle, withinGrid } from '../functions';
import { allLetters, ALL_DIRECTIONS, Direction, PlacedWord, Point, WordSearch } from '../models';
import { getAllQueryParam, getQueryObject } from './load-parameters';
import { Maybe, wrap } from './maybe';
import { nonEmptyList, parseNumber } from './validate';

const EMPTY_SPACE = '_';
const MAX_GENERATION_ATTEMPTS = 1000;
type PointAndDirection = [Point, Direction];

const placeWord = ([point, direction]: PointAndDirection, word: string): PlacedWord => ({
    startingPoint: point,
    direction,
    word
});

export const emptyGrid = (width: number, height: number): string[][] => getFilledMatrix(height, width, EMPTY_SPACE);

const getAllPointsAndDirections = (width: number, height: number): PointAndDirection[] => {
    const allPoints = joinArrays(Array.from(Array(width)).map((_, idx) => idx), Array.from(Array(height)).map((_, idx) => idx)).map(([ col, row ]) => ({ col, row }));
    return joinArrays(allPoints, ALL_DIRECTIONS);
};

export type WordSearchParameters = {
    width: number,
    height: number,
    words: string[],
    title: string
};

export const getWordSearchParameters = (): Maybe<WordSearchParameters> => {
    const queryObj = getQueryObject('width', 'height', 'title');
    const wordList = getAllQueryParam('words');

    const width = wrap(queryObj.width)
        .flatmap(parseNumber);
    const height = wrap(queryObj.height)
        .flatmap(parseNumber);
    const words = nonEmptyList(wordList);

    return width.flatmap(w =>
        height.flatmap(h =>
            words.map(maybeWords => ({
                width: w,
                height: h,
                words: maybeWords,
                title: queryObj.title || 'Unspecified title'
            }))
        )
    );
};

export const generate = (width: number, height: number, words: string[]) => {
    return new Promise<WordSearch>((resolve, reject) => {
        const tryCount = Array.from(Array(MAX_GENERATION_ATTEMPTS)).findIndex(() => {
            const wordSearch = tryGenerate(width, height, words);
            if (wordSearch) {
                resolve(wordSearch);
                return true;
            }

            return false;
        });

        if (tryCount < 0) {
            reject(`Could not generate after ${MAX_GENERATION_ATTEMPTS} attempts`);
        }
    });
};

export const tryGenerate = (width: number, height: number, words: string[]): WordSearch | null => {
    const grid = emptyGrid(width, height);
    const sanitizedWords = [...words].map(sanitize);
    const placedWords: PlacedWord[] = [];
    const placementPossibilities = getAllPointsAndDirections(width, height);

    if (sanitizedWords.some(word => {
        shuffle(placementPossibilities);
        const placement = placementPossibilities.find(place => canPlace(placeWord(place, word), grid));

        if (placement) {
            const placedWord: PlacedWord = placeWord(placement, word);
            putWordInGrid(placedWord, grid);
            placedWords.push(placedWord);
        }

        return !placement;
    })) {
        return null;
    }

    const availableCharacters = [...new Set(sanitizedWords.flatMap(x => [...x]))];
    const finalGrid = fillEmptyGridSpaces(grid, availableCharacters);

    if (verifyAllWordsExactlyOnce(sanitizedWords, finalGrid)) {
        return {
            width,
            height,
            words,
            grid: finalGrid,
            key: placedWords
        };
    }

    return null;
};

export const canPlace = (word: PlacedWord, grid: string[][]): boolean => {
    return allLetters(word).every(([letter, point]) => withinGrid(grid, point) && [EMPTY_SPACE, letter].includes(grid[point.row][point.col]));
};

export const putWordInGrid = (word: PlacedWord, grid: string[][]) => {
    allLetters(word).forEach(([letter, point]) => grid[point.row][point.col] = letter);
};

export const fillEmptyGridSpaces = (grid: string[][], availableChars: string[]) => {
    return grid.map(row => row.map(ch => ch === EMPTY_SPACE ? randomValue(availableChars) : ch));
};

export const verifyAllWordsExactlyOnce = (sanitizedWords: string[], finalGrid: string[][]): boolean => {
    return sanitizedWords.every(word => {
        const placementPossibilities = getAllPointsAndDirections(finalGrid[0].length, finalGrid.length);
        return placementPossibilities.filter(place => canPlace(placeWord(place, word), finalGrid)).length === 1;
    });
};

export const toRecord = ({ width, height, ...search }: WordSearchParameters): Record<string, string | string[]> => ({
    ...search,
    width: `${width}`,
    height: `${height}`
});

