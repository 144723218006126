import { Typography } from '@mui/material';
import { ComponentProps } from 'react';

type Props = {
    letter: string,
    answer?: boolean,
    showAnswer: boolean
} & Pick<ComponentProps<typeof Typography>, 'sx'>;

const Letter = ({ letter, answer, showAnswer, sx }: Props) => {
    return (
        <Typography sx={{ ...sx, backgroundColor: answer && showAnswer ? 'gold' : '', fontFamily: 'Courier New', lineHeight: 1, opacity: showAnswer && !answer ? 0.35 : 1 }}>{letter}</Typography>
    );
};

export default Letter;