import { useCallback } from 'react';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import RandomContainer from './RandomContainer';

type Dimensions = {
    rows: number,
    columns: number
};

type Props = {
    dimensions: Dimensions,
    onChange: (dim: Dimensions) => void
};

const EditableContainer = ({ dimensions, onChange }: Props) => {
    const addRow = useCallback(() => onChange({ ...dimensions, rows: dimensions.rows + 1 }), [dimensions, onChange]);
    const removeRow = useCallback(() => onChange({ ...dimensions, rows: dimensions.rows - 1 }), [dimensions, onChange]);
    const addColumn = useCallback(() => onChange({ ...dimensions, columns: dimensions.columns + 1 }), [dimensions, onChange]);
    const removeColumn = useCallback(() => onChange({ ...dimensions, columns: dimensions.columns - 1 }), [dimensions, onChange]);

    return (
        <Stack sx={{ alignItems: 'center' }} spacing={2}>
            <RandomContainer rows={dimensions.rows} columns={dimensions.columns} />
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={4}>
                <Stack direction="row" sx={{ alignItems: 'center '}}>
                    <Typography>Rows:</Typography>
                    <IconButton onClick={removeRow}>
                        <MinusIcon />
                    </IconButton>
                    <Typography>{dimensions.rows}</Typography>
                    <IconButton onClick={addRow}>
                        <PlusIcon />
                    </IconButton>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center '}}>
                    <Typography>Columns:</Typography>
                    <IconButton onClick={removeColumn}>
                        <MinusIcon />
                    </IconButton>
                    <Typography>{dimensions.columns}</Typography>
                    <IconButton onClick={addColumn}>
                        <PlusIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditableContainer;