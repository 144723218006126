import { Button, Stack, TextField } from '@mui/material';
import { useState, useCallback, useMemo } from 'react';
import { navigate, replace } from '../services/load-parameters';
import { getWordSearchParameters, toRecord, WordSearchParameters } from '../services/word-search';
import EditableContainer from '../components/EditableContainer';
import EditableWordList from '../components/EditableWordList';
import { sanitize } from '../functions';

const getFinalWordList = (originalWords: string[]): string[] => {
  var map = new Map<string, string>();
  originalWords.forEach(w => map.set(sanitize(w), w));

  const unique = [...map.values()];
  unique.sort();

  return unique;
};

const NewWordSearchPage = () => {
  const params = useMemo(() => getWordSearchParameters().getOrDefault(null), []);
  const [title, setTitle] = useState(params?.title || '');
  const [words, setWords] = useState<string[]>(params?.words || []);
  const [dimensions, setDimensions] = useState({ rows: params?.height || 10, columns: params?.width || 10 });

  const updateWords = useCallback((words: string[]) => {
    setWords(getFinalWordList(words));
  }, [setWords]);

  const startGeneration = useCallback(() => {
    const searchParams: WordSearchParameters = {
        words,
        title,
        width: dimensions.columns,
        height: dimensions.rows
    };

    const record = toRecord(searchParams);
    replace('new', record);
    navigate('generate', record);
  }, [dimensions, words, title]);

  const startOver = useCallback(() => {
    navigate('new', {});
  }, []);

  return (
    <Stack sx={{ alignItems: 'center' }} spacing={2}>
      <TextField sx={{ maxWidth: 500 }} fullWidth label="Title" value={title} onChange={e => setTitle(e.target.value)}/>
      <EditableContainer dimensions={dimensions} onChange={setDimensions} />
      <EditableWordList words={words} onChange={updateWords} />
      <Stack direction="row" spacing={4}>
        <Button variant="contained" onClick={startOver}>Start Over</Button>
        <Button variant="contained" onClick={startGeneration}>Generate</Button>
      </Stack>
    </Stack>
  );
};

export default NewWordSearchPage;
