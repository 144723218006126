import { useMemo } from 'react';
import { getFilledMatrix, randomLetter } from '../functions';
import LetterTable from './LetterTable';

type Props = {
    rows: number,
    columns: number
};

const RandomContainer = ({ rows, columns }: Props) => {
    const grid = useMemo(() => getFilledMatrix(rows, columns, randomLetter), [rows, columns]);
    const answers = useMemo(() => getFilledMatrix(rows, columns, false), [rows, columns]);
    return <LetterTable grid={grid} answers={answers} />;
};

export default RandomContainer;