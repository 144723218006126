import { wrap, trycatch, Maybe } from "./maybe";

export const isValidNumber = (str: string): boolean => /^[1-9][0-9]*$/.test(str);
export const isValidList = (arr: any[]) => arr.length > 0;

const convertNumber = (str: string) => {
    if (isValidNumber(str)) {
        return parseInt(str, 10);
    } else {
        throw Error(`${str} is not a valid number`);
    }
};
export const parseNumber = (str: string): Maybe<number> => trycatch(() => convertNumber(str));

export const nonEmptyList = <T>(arr: T[]): Maybe<T[]> => wrap(isValidList(arr) ? arr : null);
