import { generate, getWordSearchParameters, WordSearchParameters } from "../services/word-search";
import { navigate } from '../services/load-parameters';
import { CircularProgress, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { WordSearch } from "../models";
import WordSearchContainer from "../components/WordSearchContainer";
import { Maybe } from '../services/maybe';
import HideFromPrint from '../components/HideFromPrint';
import WordSearchActions from '../components/WordSearchActions';

const refresh = window.location.reload;
const goBack = () => navigate('new');

type Props = {
    params: Maybe<WordSearchParameters>
};

const PageComponent = ({ params }: Props) => {
    const validatedParams = params.getOrDefault(null);
    const [wordSearch, setWordSearch] = useState<WordSearch | null>(null);
    const [isGenerating, setGenerating] = useState(validatedParams !== null);
    const [showAnswer, setShowAnswer] = useState(false);
    useEffect(() => {
        if (validatedParams !== null) {
            generate(validatedParams.width, validatedParams.height, validatedParams.words).then(setWordSearch).finally(() => setGenerating(false));
        }
    }, [validatedParams]);

    if (validatedParams === null) {
        return <InvalidParametersComponent />;
    }

    if (isGenerating) {
        return <CircularProgress size="large"/>;
    } else if (!wordSearch) {
        return (
            <Stack>
                <Typography>Sorry, but the information you provided could not be used to create a word search.</Typography>
                <Typography>You can try <Link onClick={refresh}>refreshing the page</Link> to try again,</Typography>
                <Typography>or <Link onClick={goBack}>go back</Link> to update your information.</Typography>
            </Stack>
        );
    } else {
        return (
            <>
            <HideFromPrint sx={{ position: 'fixed', zIndex: 1, right: 0, top: 0 }}>
                <WordSearchActions showAnswer={showAnswer} onAnswerChange={setShowAnswer} />
            </HideFromPrint>
            <WordSearchContainer title={validatedParams.title} wordSearch={wordSearch} showAnswer={showAnswer}/>
            </>
        );
    }
};

const InvalidParametersComponent = () => {
    return (
        <Stack>
            <Typography>The URL provided is not valid. Please re-enter your information in the <Link onClick={goBack}>new word search page</Link> to try again.</Typography>
        </Stack>
    );
};

const GenerateWordSearchPage = () => {
    const searchParams = getWordSearchParameters();

    return (
        <PageComponent params={searchParams} />
    );
};

export default GenerateWordSearchPage;