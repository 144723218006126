import { useCallback, useMemo, useRef, useState } from 'react';
import Delete from '@mui/icons-material/Delete';
import Save from '@mui/icons-material/Save';
import InlineFormActions, { Action } from './InlineFormActions';
import { TextField } from '@mui/material';

type Props = {
    word: string,
    onChange: (s: string) => void,
    onDelete: () => void
};

const EditableWord = ({ word, onChange, onDelete }: Props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [innerWord, setInnerWord] = useState(word);
    const inputEl = useRef<HTMLInputElement>(null);

    const deleteAction: Action = useMemo(() => ({
        icon: <Delete />,
        key: 'delete',
        onClick: onDelete
    }), [onDelete]);

    const finalizeUpdate = useCallback(() => {
        if (innerWord) {
            onChange(innerWord);
        } else {
            onDelete();
        }

        inputEl.current?.blur();
        setIsFocused(false);
        setInnerWord('');
    }, [innerWord, onChange, onDelete]);

    const formActions: Action[] = useMemo(() => {
        if (!isFocused) {
            return [deleteAction];
        }

        const save: Action = {
            icon: <Save />,
            key: 'save',
            onClick: finalizeUpdate
        };

        return [save, deleteAction];
    }, [isFocused, finalizeUpdate, deleteAction]);

    const takeFocus = useCallback(() => setIsFocused(true), [setIsFocused]);

    const onWordChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => setInnerWord(e.target.value), [setInnerWord]);

    return (
        <TextField
        onChange={onWordChange}
        onFocus={takeFocus}
        onBlur={finalizeUpdate}
        placeholder="Enter word"
        inputRef={inputEl}
        InputProps={{
            endAdornment: <InlineFormActions actions={formActions} />
        }}
        value={innerWord}
        />
    );
};

export default EditableWord;
