import { Point } from './models';

export const randomValue = <T>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

export const swap = (array: any[], a: number, b: number): void => {
    let temp = array[a];
    array[a] = array[b];
    array[b] = temp;
};

export const shuffle = <T>(array: T[]): void => {
    for (let placeIndex = array.length - 1; placeIndex > 0; placeIndex--) {
        let randomIndex = Math.floor(Math.random() * (placeIndex + 1));
        swap(array, placeIndex, randomIndex);
    }
};

export const joinArrays = <L, R>(left: L[], right: R[]): [L, R][] => {
    return left.map(leftValue => right.map(rightValue => [leftValue, rightValue])).flat() as [L, R][];
};

type Supplier<T> = (index: number) => T;
type MaybeSupplier<T> = T | Supplier<T>;
type Maybe<T> = T | undefined;

const isSupplier = <T>(func: MaybeSupplier<T>): func is Supplier<T> => {
    return typeof func === 'function';
};

export const getFilledArray = <T>(length: number, value: MaybeSupplier<T>): T[] => {
    const mapFunc = isSupplier(value) ? value : () => value;
    return Array.from(Array(length)).map((_, index) => mapFunc(index));
};

export const getFilledMatrix = <T>(rows: number, cols: number, value: MaybeSupplier<T>) => getFilledArray(rows, () => getFilledArray(cols, value));

export const zip = <L, R>(left: L[], right: R[]): [Maybe<L>, Maybe<R>][] => getFilledArray(Math.max(left.length, right.length), index => [left[index], right[index]] as [Maybe<L>, Maybe<R>]);

export const generateKey = (...values: any[]): string => values.reduce((acc, cur) => `${acc}|${cur}`);

export const withinGrid = (grid: any[][], { row, col }: Point) => row >= 0 && col >= 0 && row < grid.length && col < grid[row].length;

export const withoutIndex = <T>(values: T[], index: number): T[] => values.filter((_, idx) => idx !== index);

export const LETTERS = 'QWERTYUIOPASDFGHJKLZXCVBNM';
export const LETTERS_ARRAY: string[] = [...LETTERS];
export const randomLetter = () => randomValue(LETTERS_ARRAY);

export const sanitize = (word: string) => word.toUpperCase().replaceAll(/[^A-Z]/g, '');
